import { createReducer, createSelector } from '@reduxjs/toolkit';
import createEnhancedThunk from '../../enhancedAsyncThunkCreator';
import { RootState } from '../../rootReducer';
import {
  AsyncOptionNamesType,
  AsyncSelectsState, GetAsyncOptionsResponse,
  GetOptionsSelectType, PostAsyncOptionsResponse,
} from './asyncSelects.types';

const initialState: AsyncSelectsState = {
  options: {
    item_type: {
      isLoading: false,
      data: [],
    },
    item_strength: {
      isLoading: false,
      data: [],
    },
    item_size: {
      isLoading: false,
      data: [],
    },
    item_distillery: {
      isLoading: false,
      data: [],
    },
    item_bottler: {
      isLoading: false,
      data: [],
    },
    item_cask_type: {
      isLoading: false,
      data: [],
    },
    item_cask_finish: {
      isLoading: false,
      data: [],
    },
    lot_admin_notes_list: {
      isLoading: false,
      data: [],
    },
    warehouse_locations: {
      isLoading: false,
      data: [],
    },
  },
};
const createAsyncThunk = createEnhancedThunk('asyncSelects');

export const getOptionsSelectAction = createAsyncThunk<GetOptionsSelectType, { name: AsyncOptionNamesType }>('getOptionsSelectAction',
  async ({ name }, thunkAPI) => {
    try {
      const response = await thunkAPI.extra.get<{ name: AsyncOptionNamesType }, GetAsyncOptionsResponse>(`/api/${name}/`, {
        params: {
          page_size: 999,
        },
      });
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue({
        messages: err?.response?.data?.data ?? { error: ['Server error'] },
      });
    }
  });

export const createOptionsSelectAction = createAsyncThunk<GetOptionsSelectType, { name: AsyncOptionNamesType, option:string }>('asyncSelects',
  async ({ name, option }, thunkAPI) => {
    try {
      const response = await thunkAPI.extra.post<{ name: AsyncOptionNamesType, option:string }, PostAsyncOptionsResponse>(`/api/${name}/`, {
        name: option,
      });
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue({
        messages: err?.response?.data?.data ?? { error: ['Server error'] },
      });
    }
  });

export default createReducer(initialState, (builder) => {
  builder.addCase(getOptionsSelectAction.fulfilled, (state, action) => ({
    ...state,
    options: {
      ...state.options,
      [action.meta.arg.name]: {
        data: action.payload?.data?.results ?? [],
        isLoading: false,
      },
    },
  }));
  builder.addCase(getOptionsSelectAction.pending, (state, action) => ({
    ...state,
    options: {
      ...state.options,
      [action.meta.arg.name]: {
        ...state.options[action.meta.arg.name],
        isLoading: true,
      },
    },
  }));
  builder.addCase(getOptionsSelectAction.rejected, (state, action) => ({
    ...state,
    options: {
      ...state.options,
      [action.meta.arg.name]: {
        ...state.options[action.meta.arg.name],
        isLoading: false,
      },
    },
  }));
  builder.addCase(createOptionsSelectAction.fulfilled, (state, action) => ({
    ...state,
    options: {
      ...state.options,
      [action.meta.arg.name]: {
        data: [...state.options[action.meta.arg.name].data, action.payload?.data],
        isLoading: false,
      },
    },
  }));
  builder.addCase(createOptionsSelectAction.pending, (state, action) => ({
    ...state,
    options: {
      ...state.options,
      [action.meta.arg.name]: {
        ...state.options[action.meta.arg.name],
        isLoading: true,
      },
    },
  }));
  builder.addCase(createOptionsSelectAction.rejected, (state, action) => ({
    ...state,
    options: {
      ...state.options,
      [action.meta.arg.name]: {
        ...state.options[action.meta.arg.name],
        isLoading: false,
      },
    },
  }));
});

export const selectOptions = (name:AsyncOptionNamesType) => createSelector(
  (state: RootState) => state.asyncSelects.options,
  (options) => options?.[name]?.data ?? [],
);

export const selectOptionsLoading = (name:AsyncOptionNamesType) => createSelector(
  (state: RootState) => state.asyncSelects.options,
  (options) => options?.[name]?.isLoading ?? false,
);
