import {
  createAction, createReducer, createSelector,
} from '@reduxjs/toolkit';
import { serialize } from 'object-to-formdata';
import { stringify } from 'qs';
import createEnhancedThunk from '../../enhancedAsyncThunkCreator';
import { RootState } from '../../rootReducer';
import { PaginationType } from '../../apiTypes/shared/pagintaion';
import { AsyncOptionsTypes } from '../asyncSelects/asyncSelects.types';
import { getFormattedErrors } from '../../../utilities';
import { GetAuctionReferenceNoResponse, GetAuctionReferenceNoType } from '../auctions/auctions.types';
import {
  EditLotType, FetchCreateLotParams, FetchEditLotParams, FetchTemplateParams,
  GetLotResponse,
  GetLotsResponse,
  GetLotsType,
  GetLotType, LotPatchType,
  LotStateType,
  LotType,
  PostLotsRelistBody,
  PostLotsRelistResponse,
  PostLotType,
  SubmitGroupItemMethod,
} from './lots.types';
import { tablePaginationParams } from '../../../staticData';
import { GetTemplateResponse, TemplateType } from '../templates/templates.types';
import { dispatchErrorMessage, successMessageAction } from '../ui/ui';
import instanceAxios from '../../../axiosInstance';
import routes from '../../../routes';

const initialErrorsState = {
  errors: null,
};

const initialState: LotStateType<string | AsyncOptionsTypes> = {
  result: [],
  isLoading: false,
  lot: {
    vat_enabled: false,
    is_featured: false,
    checked_out: false,
    reserve_met: false,
    bid_stats: undefined,
    note: '',
    buyer_paid: false,
    admin_note: null,
    reference_no: '',
    warehouse: '',
    is_group_lot: false,
    custom_description: null,
    custom_subtitle: null,
    custom_title: null,
    reserve_price: null,
    save_template: 'lotOnly',
    seller_sheet: {
      id: undefined,
      user: undefined,
      auction: undefined,
    },
    group_items: [],
    item: {
      barcode: '',
      description: '',
      subtitle: '',
      title: '',
      distillery: '',
      bottler: '',
      cask_type: '',
      cask_finish: '',
      strength: '',
      size: '',
      item_type: '',
      cask_number: null,
      approx_shipping_weight: null,
      bottles_produced: null,
    },
  },
  ...tablePaginationParams,
  ...initialErrorsState,
};

const createAsyncThunk = createEnhancedThunk('lots');

export const fetchTemplate = (
  {
    fetchMethod, itemData, id,
    method,
  }: FetchTemplateParams,
): Promise<GetTemplateResponse> => {
  const link = id ? `/api/items/${id}/` : '/api/items/';
  const formData = serialize({
    title: itemData?.title,
    barcode: itemData?.barcode,
    subtitle: itemData?.subtitle,
    description: itemData?.description,
    cask_number: itemData?.cask_number,
    approx_shipping_weight: itemData?.approx_shipping_weight,
    bottles_produced: itemData?.bottles_produced,
    item_type: itemData?.item_type?.id || null,
    strength: itemData?.strength?.id || null,
    size: itemData?.size?.id || null,
    distillery: itemData?.distillery?.id || null,
    bottler: itemData?.bottler?.id || null,
    cask_type: itemData?.cask_type?.id || null,
    cask_finish: itemData?.cask_finish?.id || null,
    is_template: itemData?.is_template,
    photo: itemData?.photo,
  }, { indices: true }, undefined, 'object');

  return fetchMethod[method]?.(link, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

const fetchCreateLot = async ({
  thunkAPI, data, itemData, itemGroupData,
}: FetchCreateLotParams) => thunkAPI.post('/api/lots/', {
  admin_note: typeof data.admin_note === 'string' ? data.admin_note : data.admin_note?.name,
  reference_no: data.reference_no,
  warehouse: data.warehouse?.id,
  reserve_price: data.reserve_price,
  seller_sheet: data.seller_sheet?.id,
  item: itemData,
  group_items: itemGroupData,
  admin_note_save_new: data.admin_note_save_new,
  custom_title: data.custom_title,
  custom_subtitle: data.custom_subtitle,
  custom_description: data.custom_description,
  is_group_lot: data.is_group_lot || undefined,
});

const fetchEditLot = async ({
  thunkAPI, data, itemData, id, itemGroupData,
}: FetchEditLotParams) => {
  const formData = serialize({
    photos: data?.photos,
    admin_note: typeof data.admin_note === 'string' ? data.admin_note : data.admin_note?.name,
    reference_no: data.reference_no,
    warehouse: data.warehouse?.id,
    reserve_price: data.reserve_price,
    seller_sheet: data.seller_sheet?.id,
    admin_note_save_new: data.admin_note_save_new,
    item: itemData,
    group_items: itemGroupData,
    is_featured: data.is_featured,
    custom_title: data.custom_title,
    custom_subtitle: data.custom_subtitle,
    custom_description: data.custom_description,
    is_group_lot: data.is_group_lot || undefined,
  }, { indices: true }, undefined, 'object');
  return thunkAPI.patch(`/api/lots/${id}/`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
};

export const createLotUpdateAction = createAsyncThunk<PostLotType, { data: LotType<AsyncOptionsTypes> }>('createLotUpdateAction',
  async ({ data }, thunkAPI) => {
    const groupItemsIds = data?.group_items?.map((item: TemplateType<any>) => item.id) || undefined;
    try {
      const response = await fetchCreateLot({
        itemGroupData: groupItemsIds?.filter((item) => typeof item === "number") as number[] || undefined,
        thunkAPI: thunkAPI.extra,
        data,
        itemData: data?.item?.id || undefined,
      });

      return response.data;
    } catch (err) {
      if (err?.response?.config.url === '/api/items/') {
        return thunkAPI.rejectWithValue({
          messages: err?.response?.data?.data ? getFormattedErrors(err?.response?.data?.data, 'item') : { error: ['Server error'] },
        });
      }
      return thunkAPI.rejectWithValue({
        messages: err?.response?.data?.data ?? { error: ['Server error'] },
      });
    }
  });

export const createLotUpdateActionHideTemplate = createAsyncThunk<PostLotType, { data: LotType<AsyncOptionsTypes> }>('createLotUpdateActionHideTemplate',
  async ({ data }, thunkAPI) => {
    try {
      const templateResponse = await fetchTemplate({
        method: 'post',
        fetchMethod: thunkAPI.extra,
        itemData: { ...data.item, is_template: true },
      });
      const response = await fetchCreateLot(
        {
          thunkAPI: thunkAPI.extra, data, itemData: templateResponse.data.data.id,
        },
      );

      return response.data;
    } catch (err) {
      if (err?.response?.config.url === '/api/items/') {
        return thunkAPI.rejectWithValue({
          messages: err?.response?.data?.data ? getFormattedErrors(err?.response?.data?.data, 'item') : { error: ['Server error'] },
        });
      }
      return thunkAPI.rejectWithValue({
        messages: err?.response?.data?.data ?? { error: ['Server error'] },
      });
    }
  });

export const createLotSaveTemplateAction = createAsyncThunk<PostLotType, { data: LotType<AsyncOptionsTypes> }>('createLotSaveTemplateAction',
  async ({ data }, thunkAPI) => {
    try {
      const templateResponse = await fetchTemplate({
        method: 'post',
        fetchMethod: thunkAPI.extra,
        itemData: { ...data.item, is_template: false },
      });
      const response = await fetchCreateLot(
        {
          thunkAPI: thunkAPI.extra, data, itemData: templateResponse.data.data.id,
        },
      );

      return response.data;
    } catch (err) {
      if (err?.response?.config.url === '/api/items/') {
        return thunkAPI.rejectWithValue({
          messages: err?.response?.data?.data ? getFormattedErrors(err?.response?.data?.data, 'item') : { error: ['Server error'] },
        });
      }
      return thunkAPI.rejectWithValue({
        messages: err?.response?.data?.data ?? { error: ['Server error'] },
      });
    }
  });

export const createLotOverWriteTemplateAction = createAsyncThunk<PostLotType, { data: LotType<AsyncOptionsTypes> }>('createLotOverWriteTemplateAction',
  async ({ data }, thunkAPI) => {
    try {
      const templateResponse = await fetchTemplate(
        {
          method: 'patch',
          fetchMethod: thunkAPI.extra,
          itemData: data.item,
          id: data.item.id as number,
        },
      );
      const response = await fetchCreateLot({
        thunkAPI: thunkAPI.extra,
        data,
        itemData: templateResponse.data.data.id,
      });

      return response.data;
    } catch (err) {
      if (err?.response?.config.url === '/api/items/') {
        return thunkAPI.rejectWithValue({
          messages: err?.response?.data?.data ? getFormattedErrors(err?.response?.data?.data, 'item') : { error: ['Server error'] },
        });
      }
      return thunkAPI.rejectWithValue({
        messages: err?.response?.data?.data ?? { error: ['Server error'] },
      });
    }
  });

export const editLotUpdateAction = createAsyncThunk<EditLotType, { id: number, data: LotPatchType<string | AsyncOptionsTypes> }>('editLotUpdateAction',
  async ({ id, data }, thunkAPI) => {
    const groupItemsIds = data?.group_items?.map((item: TemplateType<any>) => item.id) || undefined;
    try {
      const response = await fetchEditLot(
        {
          thunkAPI: thunkAPI.extra,
          data: data as LotType<AsyncOptionsTypes>,
          itemData: data?.item?.id || undefined,
          itemGroupData: groupItemsIds?.filter((item) => typeof item === "number") as number[] || undefined,
          id,
        },
      );
      return response.data;
    } catch (err) {
      if (err?.response?.config.url === '/api/items/') {
        return thunkAPI.rejectWithValue({
          messages: err?.response?.data?.data ? getFormattedErrors(err?.response?.data?.data, 'item') : { error: ['Server error'] },
        });
      }
      return thunkAPI.rejectWithValue({
        messages: err?.response?.data?.data ?? { error: ['Server error'] },
      });
    }
  });

export const editBulkLotUpdateAction = createAsyncThunk<any, any>('editBulkLotUpdateAction',
  async (data, thunkAPI) => {
    try {
      const response = await thunkAPI.extra.patch<any, any>('/api/lots/', data);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue({
        messages: err?.response?.data?.data ?? { error: ['Server error'] },
      });
    }
  });

export const editLotUpdateActionHideTemplate = createAsyncThunk<EditLotType, { id: number, data: LotPatchType<string | AsyncOptionsTypes> }>('editLotUpdateActionHideTemplate',
  async ({ id, data }, thunkAPI) => {
    try {
      const templateResponse = await fetchTemplate({
        method: 'post',
        fetchMethod: thunkAPI.extra,
        itemData: { ...data.item, is_template: true } as TemplateType<AsyncOptionsTypes>,
      });
      const response = await fetchEditLot(
        {
          thunkAPI: thunkAPI.extra,
          data: data as LotType<AsyncOptionsTypes>,
          itemData: templateResponse.data.data.id,
          id,
        },
      );
      return response.data;
    } catch (err) {
      if (err?.response?.config.url === '/api/items/') {
        return thunkAPI.rejectWithValue({
          messages: err?.response?.data?.data ? getFormattedErrors(err?.response?.data?.data, 'item') : { error: ['Server error'] },
        });
      }
      return thunkAPI.rejectWithValue({
        messages: err?.response?.data?.data ?? { error: ['Server error'] },
      });
    }
  });

export const editLotSaveTemplateAction = createAsyncThunk<EditLotType, { id: number, data: LotPatchType<string | AsyncOptionsTypes> }>('editLotSaveTemplateAction',
  async ({ id, data }, thunkAPI) => {
    try {
      const templateResponse = await fetchTemplate({
        method: 'post',
        fetchMethod: thunkAPI.extra,
        itemData: { ...data.item, is_template: false } as TemplateType<AsyncOptionsTypes>,
      });
      const response = await fetchEditLot(
        {
          thunkAPI: thunkAPI.extra,
          data: data as LotType<AsyncOptionsTypes>,
          itemData: templateResponse.data.data.id,
          id,
        },
      );
      return response.data;
    } catch (err) {
      if (err?.response?.config.url === '/api/items/') {
        return thunkAPI.rejectWithValue({
          messages: err?.response?.data?.data ? getFormattedErrors(err?.response?.data?.data, 'item') : { error: ['Server error'] },
        });
      }
      return thunkAPI.rejectWithValue({
        messages: err?.response?.data?.data ?? { error: ['Server error'] },
      });
    }
  });

export const editLotOverWriteTemplateAction = createAsyncThunk<EditLotType, { id: number, data: LotPatchType<string | AsyncOptionsTypes> }>('editLotOverWriteTemplateAction',
  async ({ id, data }, thunkAPI) => {
    try {
      const templateResponse = await fetchTemplate(
        {
          method: 'patch',
          fetchMethod: thunkAPI.extra,
          itemData: data.item as TemplateType<AsyncOptionsTypes>,
          id: data?.item?.id as number,
        },
      );
      const response = await fetchEditLot(
        {
          thunkAPI: thunkAPI.extra,
          data: data as LotType<AsyncOptionsTypes>,
          itemData: templateResponse.data.data.id,
          id,
        },
      );

      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue({
        messages: err?.response?.data?.data ? getFormattedErrors(err?.response?.data?.data, 'item') : { error: ['Server error'] },
      });
    }
  });

export const getLotsAction = createAsyncThunk<GetLotsType, PaginationType & any>('lots',
  async ({ page, perPage, params }, thunkAPI) => {
    try {
      const response = await thunkAPI.extra.get<PaginationType, GetLotsResponse>('/api/lots/', {
        params: {
          // admin param used for getting corresponding data by server for the admin area
          admin: true,
          page: page + 1,
          page_size: perPage,
          ...params,
        },
      });
      return response.data;
    } catch (err) {
      thunkAPI.dispatch(dispatchErrorMessage(err));
      return thunkAPI.rejectWithValue({
        messages: err?.response?.data?.data ?? { error: ['Server error'] },
      });
    }
  });

export const getLotAction = createAsyncThunk<GetLotType, { id?: number }>('getLot',
  async ({ id }, thunkAPI) => {
    try {
      const response = await thunkAPI.extra.get<{ id?: number }, GetLotResponse>(`/api/lots/${id}/`);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue({
        messages: err?.response?.data?.data ?? { error: ['Server error'] },
      });
    }
  });

export const deleteLotAction = createAsyncThunk<null, { id?: number }>('deleteLotAction',
  async ({ id }, thunkAPI) => {
    try {
      return await thunkAPI.extra.delete(`/api/lots/${id}/`);
    } catch (err) {
      return thunkAPI.rejectWithValue({
        messages: err?.response?.data?.data ?? { error: ['Server error'] },
      });
    }
  });

export const bulkDeleteLotAction = createAsyncThunk<null, any>('bulkDeleteLotAction',
  async (ids, thunkAPI) => {
    try {
      return await thunkAPI.extra.delete('/api/lots/', {
        headers: {
          'X-BULK-OPERATION': 'true',
        },
        params: {
          id: ids.keys,
        },
        paramsSerializer: (params) => stringify(params, { arrayFormat: 'comma' }),
      });
    } catch (err) {
      return thunkAPI.rejectWithValue({
        messages: err?.response?.data?.data ?? { error: ['Server error'] },
      });
    }
  });

export const getReferenceNoAuctionAction = createAsyncThunk<GetAuctionReferenceNoType['data'], { id: number }>('getSeqNoAuction',
  async ({ id }, thunkAPI) => {
    try {
      const response = await thunkAPI.extra.get<{ id: number }, GetAuctionReferenceNoResponse>(`/api/auctions/${id}/next_ref_no/`);
      return response.data.data;
    } catch (err) {
      return thunkAPI.rejectWithValue({
        messages: err?.response?.data?.data ?? { error: ['Server error'] },
      });
    }
  });

export const getSellerLotsAction = createAsyncThunk<GetLotsType, { id: number } & PaginationType & any>('sellerLots',
  async ({
    page, perPage, id, params,
  }, thunkAPI) => {
    try {
      const response = await thunkAPI.extra.get<PaginationType, GetLotsResponse>(`/api/seller_sheets/${id}/lots/`, {
        params: {
          // admin param used for getting corresponding data by server for the admin area
          admin: true,
          page: page + 1,
          page_size: perPage,
          ...params,
        },
      });
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue({
        messages: err?.response?.data?.data ?? { error: ['Server error'] },
      });
    }
  });

export const postRelistLots = createAsyncThunk<PostLotsRelistResponse['data'], PostLotsRelistBody>('postRelistLots',
  async (body, thunkAPI) => {
    try {
      const response = await thunkAPI.extra.post<PostLotsRelistResponse>(`/api/admin_relist_lots/`, body);
      return response.data.data;
    } catch (err) {
      thunkAPI.dispatch(dispatchErrorMessage(err));
      return thunkAPI.rejectWithValue({});
    }
  });

export const postMergeLots = createAsyncThunk<LotType<any>[], number[]>('postMergeLots',
  async (lots, thunkAPI) => {
    try {
      const state = thunkAPI.getState();
      const response = await thunkAPI.extra.post<GetLotResponse['data']>(`/api/merge_lots/`, { lots });
      const newLot = response.data.data;
      const removedMergedLots = state.lots.result.filter((lot) => !lots.includes(lot.id!));
      const newList = [...removedMergedLots, newLot];
      thunkAPI.dispatch(successMessageAction("Lots were merged successfully"));
      window.open(`${routes.lots}/${newLot.id}`, '_blank');
      return newList;
    } catch (err) {
      thunkAPI.dispatch(dispatchErrorMessage(err));
      return thunkAPI.rejectWithValue({});
    }
  });

export const resetSellerLots = createAction('resetSellerLots');

export const removeLots = createAction<string[]>('removeLots');

export const resetLotForm = createAction('resetLotForm');

export const resetLotsState = createAction('resetLotsResult');

const updatedLots = (
  state: any,
  payload: LotType<AsyncOptionsTypes>,
) => state.result
  .map((lot: LotType<string | AsyncOptionsTypes>) => {
    if (lot.id === payload.id) {
      return payload;
    }
    return lot;
  });

export const submitGroupItemLotOnly: SubmitGroupItemMethod = async ({
  index,
  data,
  resetItem,
  getValues,
  setValue,
}) => {
  const newGropItems = getValues().group_items.map((item, idx) => (idx === index ? { ...data, newTemplateId: undefined } : item));
  setValue('group_items', newGropItems, { shouldDirty: true });
  resetItem({ ...data, newTemplateId: undefined });
};

export const submitGroupItemUpdateTemplate: SubmitGroupItemMethod = async ({
  index,
  data,
  resetItem,
  getValues,
  setValue,
}) => {
  const transformedPhoto = data.photo && {
    id: data.photo.id,
    title: data.photo?.title,
  };
  const { data: { data: template } } = await fetchTemplate({
    method: 'post',
    fetchMethod: instanceAxios,
    itemData: { ...data, is_template: true, photo: transformedPhoto },
  });
  const newGropItems = getValues().group_items.map((item, idx) => (idx === index ? template : item));
  setValue('group_items', newGropItems, { shouldDirty: true });
  resetItem(template);
};

export const submitGroupItemSaveTemplate: SubmitGroupItemMethod = async ({
  index,
  data,
  resetItem,
  getValues,
  setValue,
}) => {
  const transformedPhoto = data.photo && {
    id: data.photo.id,
    title: data.photo?.title,
  };
  const { data: { data: template } } = await fetchTemplate({
    method: 'post',
    fetchMethod: instanceAxios,
    itemData: { ...data, is_template: false, photo: transformedPhoto },
  });
  const newGropItems = getValues().group_items.map((item, idx) => (idx === index ? template : item));
  setValue('group_items', newGropItems, { shouldDirty: true });
  resetItem(template);
};

export const submitGroupOverwriteTemplate: SubmitGroupItemMethod = async ({
  index,
  data,
  resetItem,
  getValues,
  setValue,
}) => {
  const transformedPhoto = data.photo && {
    id: data.photo.id,
    title: data.photo?.title,
  };
  const { data: { data: template } } = await fetchTemplate(
    {
      method: 'patch',
      fetchMethod: instanceAxios,
      itemData: { ...data, photo: transformedPhoto } as TemplateType<AsyncOptionsTypes>,
      id: data.id as number,
    },
  );
  const newGropItems = getValues().group_items.map((item, idx) => (idx === index ? template : item));
  setValue('group_items', newGropItems, { shouldDirty: false });
  resetItem(template);
};

export default createReducer(initialState, (builder) => {
  builder.addCase(createLotUpdateAction.fulfilled, (state, action) => {
    const lots = updatedLots({ ...state }, action.payload.data);
    return ({
      ...state,
      ...initialErrorsState,
      lot: {
        ...action.payload.data,
        item: action.payload.data?.item ? action.payload.data?.item : state.lot.item,
      },
      result: [...lots, action.payload.data],
      count: state.count + 1,
      isLoading: false,
    });
  });
  builder.addCase(createLotUpdateAction.pending, (state) => ({
    ...state,
    isLoading: true,
  }));
  builder.addCase(createLotUpdateAction.rejected, (state, action) => ({
    ...state,
    ...initialErrorsState,
    // @ts-ignore
    errors: action?.payload?.messages,
    isLoading: false,
  }));

  builder.addCase(createLotUpdateActionHideTemplate.fulfilled, (state, action) => {
    const lots = updatedLots(state, action.payload.data);
    return ({
      ...state,
      ...initialErrorsState,
      lot: {
        ...action.payload.data,
        item: action.payload.data?.item ? action.payload.data?.item : state.lot.item,
      },
      result: [...lots, action.payload.data],
      count: state.count + 1,
      isLoading: false,
    });
  });
  builder.addCase(createLotUpdateActionHideTemplate.pending, (state) => ({
    ...state,
    isLoading: true,
  }));
  builder.addCase(createLotUpdateActionHideTemplate.rejected, (state, action) => ({
    ...state,
    ...initialErrorsState,
    // @ts-ignore
    errors: action?.payload?.messages,
    isLoading: false,
  }));

  builder.addCase(createLotSaveTemplateAction.fulfilled, (state, action) => {
    const lots = updatedLots(state, action.payload.data);
    return ({
      ...state,
      ...initialErrorsState,
      lot: {
        ...action.payload.data,
        item: action.payload.data?.item ? action.payload.data?.item : state.lot.item,
      },
      result: [...lots, action.payload.data],
      count: state.count + 1,
      isLoading: false,
    });
  });
  builder.addCase(createLotSaveTemplateAction.pending, (state) => ({
    ...state,
    isLoading: true,
  }));
  builder.addCase(createLotSaveTemplateAction.rejected, (state, action) => ({
    ...state,
    ...initialErrorsState,
    // @ts-ignore
    errors: action?.payload?.messages,
    isLoading: false,
  }));

  builder.addCase(createLotOverWriteTemplateAction.fulfilled, (state, action) => {
    const lots = updatedLots(state, action.payload.data);
    return ({
      ...state,
      ...initialErrorsState,
      lot: {
        ...action.payload.data,
        item: action.payload.data?.item ? action.payload.data?.item : state.lot.item,
      },
      result: [...lots, action.payload.data],
      count: state.count + 1,
      isLoading: false,
    });
  });
  builder.addCase(createLotOverWriteTemplateAction.pending, (state) => ({
    ...state,
    isLoading: true,
  }));
  builder.addCase(createLotOverWriteTemplateAction.rejected, (state, action) => ({
    ...state,
    ...initialErrorsState,
    // @ts-ignore
    errors: action?.payload?.messages,
    isLoading: false,
  }));

  builder.addCase(editLotUpdateAction.fulfilled, (state, action) => {
    const lots = updatedLots(state, action.payload.data);
    return ({
      ...state,
      ...initialErrorsState,
      lot: {
        ...action.payload.data,
        item: action.payload.data?.item ? action.payload.data?.item : state.lot.item,
      },
      result: lots,
      isLoading: false,
    });
  });
  builder.addCase(editLotUpdateAction.pending, (state) => ({
    ...state,
    isLoading: true,
  }));
  builder.addCase(editLotUpdateAction.rejected, (state, action) => ({
    ...state,
    ...initialErrorsState,
    // @ts-ignore
    errors: action?.payload?.messages,
    isLoading: false,
  }));

  builder.addCase(editLotUpdateActionHideTemplate.fulfilled, (state, action) => {
    const lots = updatedLots(state, action.payload.data);

    return ({
      ...state,
      ...initialErrorsState,
      lot: action.payload.data,
      result: lots,
      isLoading: false,
    });
  });
  builder.addCase(editLotUpdateActionHideTemplate.pending, (state) => ({
    ...state,
    isLoading: true,
  }));
  builder.addCase(editLotUpdateActionHideTemplate.rejected, (state, action) => ({
    ...state,
    ...initialErrorsState,
    // @ts-ignore
    errors: action?.payload?.messages,
    isLoading: false,
  }));

  builder.addCase(editLotSaveTemplateAction.fulfilled, (state, action) => {
    const lots = updatedLots(state, action.payload.data);

    return ({
      ...state,
      ...initialErrorsState,
      lot: action.payload.data,
      result: lots,
      isLoading: false,
    });
  });
  builder.addCase(editLotSaveTemplateAction.pending, (state) => ({
    ...state,
    isLoading: true,
  }));
  builder.addCase(editLotSaveTemplateAction.rejected, (state, action) => ({
    ...state,
    ...initialErrorsState,
    // @ts-ignore
    errors: action?.payload?.messages,
    isLoading: false,
  }));

  builder.addCase(editLotOverWriteTemplateAction.fulfilled, (state, action) => {
    const lots = updatedLots(state, action.payload.data);
    return ({
      ...state,
      ...initialErrorsState,
      lot: action.payload.data,
      result: lots,
      isLoading: false,
    });
  });
  builder.addCase(editLotOverWriteTemplateAction.pending, (state) => ({
    ...state,
    isLoading: true,
  }));
  builder.addCase(editLotOverWriteTemplateAction.rejected, (state, action) => ({
    ...state,
    ...initialErrorsState,
    // @ts-ignore
    errors: action?.payload?.messages,
    isLoading: false,
  }));

  builder.addCase(getLotsAction.fulfilled, (state, { payload, meta }) => ({
    ...state,
    ...initialErrorsState,
    result: payload.data.results,
    count: payload.data.count,
    page: meta.arg.page,
    perPage: meta.arg.perPage,
    isLoading: false,
  }));
  builder.addCase(getLotsAction.pending, (state) => ({
    ...state,
    isLoading: true,
  }));
  builder.addCase(getLotsAction.rejected, (state) => ({
    ...state,
    ...initialErrorsState,
    isLoading: false,
  }));
  builder.addCase(getLotAction.fulfilled, (state, { payload }) => ({
    ...state,
    ...initialErrorsState,
    lot: {
      ...payload.data,
      item: payload.data?.item ? payload.data?.item : state.lot.item,
    },
    isLoading: false,
  }));
  builder.addCase(getLotAction.pending, (state) => ({
    ...state,
    isLoading: true,
  }));
  builder.addCase(getLotAction.rejected, (state, action) => ({
    ...state,
    ...initialErrorsState,
    // @ts-ignore
    errors: action?.payload?.messages,
    isLoading: false,
  }));
  builder.addCase(getReferenceNoAuctionAction.fulfilled, (state) => ({
    ...state,
    ...initialErrorsState,
    isLoading: false,
  }));
  builder.addCase(getReferenceNoAuctionAction.pending, (state) => ({
    ...state,
    isLoading: true,
  }));
  builder.addCase(getReferenceNoAuctionAction.rejected, (state) => ({
    ...state,
    ...initialErrorsState,
    isLoading: false,
  }));

  builder.addCase(deleteLotAction.fulfilled, (state) => ({
    ...state,
    ...initialErrorsState,
    isLoading: false,
  }));
  builder.addCase(deleteLotAction.pending, (state) => ({
    ...state,
    isLoading: true,
  }));
  builder.addCase(deleteLotAction.rejected, (state, action) => ({
    ...state,
    ...initialErrorsState,
    // @ts-ignore
    errors: action?.payload?.messages,
    isLoading: false,
  }));

  builder.addCase(bulkDeleteLotAction.fulfilled, (state) => ({
    ...state,
    ...initialErrorsState,
    isLoading: false,
  }));
  builder.addCase(bulkDeleteLotAction.pending, (state) => ({
    ...state,
    isLoading: true,
  }));
  builder.addCase(bulkDeleteLotAction.rejected, (state, action) => ({
    ...state,
    ...initialErrorsState,
    // @ts-ignore
    errors: action?.payload?.messages,
    isLoading: false,
  }));

  builder.addCase(resetLotForm.type, (state) => ({
    ...state,
    ...initialErrorsState,
    lot: initialState.lot,
  }));
  builder.addCase(resetLotsState.type, () => ({
    ...initialState,
    ...initialErrorsState,
  }));
  builder.addCase(getSellerLotsAction.fulfilled, (state, { payload, meta }) => ({
    ...state,
    ...initialErrorsState,
    result: payload.data.results,
    count: payload.data.count,
    page: meta.arg.page,
    perPage: meta.arg.perPage,
    isLoading: false,
  }));
  builder.addCase(getSellerLotsAction.pending, (state) => ({
    ...state,
    isLoading: true,
  }));
  builder.addCase(getSellerLotsAction.rejected, (state) => ({
    ...state,
    ...initialErrorsState,
    isLoading: false,
  }));

  builder.addCase(resetSellerLots, () => ({
    ...initialState,
    ...initialErrorsState,
  }));

  builder.addCase(removeLots, (state, { payload }) => {
    const filteredLots = state.result
      .filter((object) => payload.indexOf(object.id as any) === -1);
    return ({
      ...state,
      count: state.count - payload.length,
      result: filteredLots,
    });
  });

  builder.addCase(postMergeLots.fulfilled, (state, { payload }) => ({
    ...state,
    result: payload,
  }));
});

export const selectLoadingStatus = createSelector(
  (state: RootState) => state.lots.isLoading,
  (id) => id,
);

export const selectLots = createSelector(
  (state: RootState) => state.lots.result,
  (id) => id,
);

export const selectLotsCount = createSelector(
  (state: RootState) => state.lots.count,
  (id) => id,
);

export const selectLotsPage = createSelector(
  (state: RootState) => state.lots.page,
  (id) => id,
);

export const selectLotsPerPage = createSelector(
  (state: RootState) => state.lots.perPage,
  (id) => id,
);

export const selectLot = createSelector(
  (state: RootState) => state.lots.lot,
  (id) => id,
);

export const selectLotSeller = createSelector(
  (state: RootState) => state.lots.lot.seller_sheet,
  (seller_sheet) => seller_sheet,
);

export const selectErrors = createSelector(
  (state: RootState) => state.lots.errors,
  (id) => id,
);
